import { getTodayDateYYYYMMDD } from '../date/date';

export const downloadCSV = function (data: string, filename?: string) {
  // UTF-8のために付加
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);

  const blob = new Blob([bom, data], { type: 'text/csv' });

  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');

  a.setAttribute('href', url);

  a.setAttribute('download', filename || 'download.csv');

  a.click();
};

export const makeCSV = (header: string[], data: string[][]): string => {
  const csv = [header.join(','), ...data.map((row) => row.join(','))].join('\r\n');
  return csv;
};

export const makeCSVWithLeftHeader = (header: string[], data: string[][]): string => {
  const lines = header.map((headerLabel, index) => {
    const row = data.map((row) => row[index]);
    return [headerLabel, row.join(',')];
  });

  return lines.join('\r\n');
};

export const getFileNameForDownload = (prefix: string): string => {
  const currentDate = getTodayDateYYYYMMDD();
  return `${prefix}_download_${currentDate}`;
};
